<template>
  <BaseLayout title="交易动态">
    <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh">

      <van-list class="list" v-model:loading="state.loading" :finished="state.finished" loading-text="正在请求数据 ..." finished-text="没有更多了" :immediate-check="false" @load="onLoad">
        
        <van-cell-group class="block" :border="false" v-for="(item,index) in state.list" :key="index">
          <van-cell class="dt" :title="item.dt" :value="item.type" />
          <van-cell class="item" icon-prefix="icon-symbol" :icon="item.symbol.toLowerCase()"  :border="false" :title="item.symbol.toUpperCase() + ' / USDT'" :label="item.info"/>
        </van-cell-group>

      </van-list>

    </van-pull-refresh>
  </BaseLayout>
</template>

<style scoped>
.list {
  padding: 10px;
  min-height: 600px;
  background: #FFF;
  border-top: solid 1px #EEE;
}
.block {
  background: #FFF;
  text-align: left;
}
.block:nth-child(even).van-cell-group {
  background: #F9F9F9 !important;
}
.dt {
  padding: 10px 0 3px 20px;
  background: transparent;
  color: #999;
  font-size: 12px;
}
.item {
  padding-bottom: 20px;
  background: transparent;
  line-height: 28px;
}
</style>

<script>
import {reactive} from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  setup() {
    const state = reactive({
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      offset: 0,
      limit : 20
    });
    return { 
      state
    };
  },

  mounted(){
    this.onRefresh();
  },

  methods: {
    onRefresh() {
      this.state.loading = true;
      this.state.finished = false;
      this.state.offset = 0;
      this.state.list = [];
      this.onLoad();
    },
    onLoad() {
      this.$http.get("/exchange/recent?mine=1&offset=" + this.state.offset + "&limit=" + this.state.limit).then((data) => {
        this.state.refreshing = false;
        if (data.length == 0)
          return this.state.finished = true;
        this.state.loading = false;
        this.state.offset += data.length;

        for (let i in data){
          let item = data[i];
          this.state.list.push({
            symbol: item.symbol.replace(/usdt$/, ""),
            type: "「" + ["清仓", "建仓", "加仓", "补仓"][item.type] + "」",
            info:  "$" + item.amount.replace(/(\.\d\d)(\d+)$/, "$1") + " / $" + item.price,
            dt: item.dt,
          });
        }
      });
    },
  }
}
</script>
